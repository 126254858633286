<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="分组">
        <el-input
            v-model="queryForm.groupName"
            placeholder="请输入分组"
            size="small"
            @input="queryFriendlink"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="名称">
        <el-input
            v-model="queryForm.name"
            placeholder="请输入名称"
            size="small"
            @input="queryFriendlink"
            clearable
            @clear="clearQueryInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button icon="el-icon-plus" @click="addInfo" size="small" v-show="hasAddPermision"
        >新增
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        style="width: 100%"
    >
      <el-table-column prop="groupName" label="分组" align="center"/>
      <el-table-column prop="name" label="名称" align="center"/>
      <el-table-column prop="icon" label="图标" align="center">
        <template #default="scope">
          <div style="width: 30px;height:30px;margin:0 auto;">
            <img v-if="scope.row.icon!='' && scope.row.icon!=null" :src="serverUrl+scope.row.icon" style="width: 100%;height: 100%;"/>
            <span v-else>无</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="地址" align="center">
        <template #default="scope">
          <el-link @click="openLink(scope.row.url)">{{ scope.row.url }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="seq" label="排序" width="180" align="center">
        <template #default="scope">
          <el-input-number
              size="small"
              v-model="scope.row.seq"
              v-if="hasEditPermision == true"
              @change="rowEditName(scope.$index,scope.row)"
              :min="1"
          ></el-input-number>
          <span v-if="hasEditPermision == false">{{ scope.row.seq }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="clicks"
          label="浏览量"
          width="100"
          align="center"
      />
      <el-table-column label="操作" align="center" fixed="right" width="180" v-if="hasEditPermision || hasDeletePermision">
        <template #default="scope">
          <el-button v-show="hasEditPermision" icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除该链接吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button icon="el-icon-delete" size="mini" v-show="hasDeletePermision" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        v-model:currentPage="curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

  <!--友情新增-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="30%"
      @close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="分组" prop="groupName" class="label-color">
        <el-input
            v-model="ruleForm.groupName"
            placeholder="请输入分组"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="名称" prop="name" class="label-color">
        <el-input
            v-model="ruleForm.name"
            placeholder="请输入名称"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="icon" class="label-color">
        <el-upload
            class="avatar-uploader"
            :action="thumbnailAction"
            :on-change="uploadImage"
            :show-file-list="false"
            :before-upload="beforeThumbnailUpload">
          <img v-if="ruleForm.icon!=null && ruleForm.icon!=''" :src="ruleForm.icon" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-link @click="ruleForm.icon=''" type="primary" v-if="!isSave" :underline="false">清空</el-link>
      </el-form-item>
      <el-form-item label="地址" prop="url" class="label-color">
        <el-input
            v-model="ruleForm.url"
            placeholder="请输入地址"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="显示排序" prop="seq" class="label-color">
        <el-input-number
            v-model="ruleForm.seq"
            :min="1"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveInfo" v-if="isSave == true">保存</el-button>
        <el-button type="primary" @click="saveInfo" v-else>修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import friendlinkApi from "@/api/info/friendlink";
import imageUtil from '@/utils/imageUtil'
import config from '@/api/config'
export default {
  data() {
    return {
      serverUrl:config.server,
      hasAddPermision: false,
      hasEditPermision: false,
      hasDeletePermision: false,
      queryForm: {
        name: "",
        pageSize: 15,
        curPage: 1,
      },
      loading: false,
      total: 0,
      tableData: [],
      isSave: false,
      ruleForm: {
        id: "",
        name: "",
        url: "",
        holdTime: "",
        seq: "",
      },
      rules: {
        groupName: {
          required: true,
          message: "请输入分组",
          trigger: "blur",
        },
        name: {
          required: true,
          message: "请输入名称",
          trigger: "blur",
        },
        url: {
          required: true,
          message: "请输入地址",
          trigger: "blur",
        },
        seq: {
          required: true,
          message: "请输入排序",
          trigger: "blur",
        },
      },
      dialogTitle: "",
      dialogVisible: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 获取设置按钮权限
      this.hasAddPermision = this.$menuUtil.hasMenu('/info/friendlinkManage:add');
      this.hasEditPermision = this.$menuUtil.hasMenu('/info/friendlinkManage:edit');
      this.hasDeletePermision = this.$menuUtil.hasMenu('/info/friendlinkManage:delete');
    })
    this.queryFriendlink();
  },
  methods: {
    //上传图片转base64
    uploadImage(file, fileList){
      imageUtil.getBase64(file.raw).then(res =>{
        this.ruleForm.icon = res;
      })
    },

    beforeThumbnailUpload(file) {
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isJPG = types.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPEG、JPG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 新标签打开链接
    openLink(url){
      if(url&&url!=''){
        window.open(url)
      }
    },
    //行内编辑名称,地址，排序
    rowEditName(index, row) {
      console.log(index, row);
      friendlinkApi.rowEditName({
        id: row.id,
        groupName: row.groupName,
        name: row.name,
        url: row.url,
        seq: row.seq
      }).then(response => {
        if (response.data.code == 1) {
          this.$notify({
            title: response.data.msg,
            type: 'success',
            offset: 40,
            duration: 2000
          });
          this.queryFriendlink();
        } else if (response.data.code == 0) {
          this.$notify({
            title: response.data.msg,
            type: 'error',
            offset: 40,
            duration: 2000
          });
          return;
        }
      })
    },
    clearQueryInput() {
      this.queryFriendlink();
    },
    submit() {
      this.queryForm.curPage = 1;
      this.queryFriendlink();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryFriendlink();
    },
    handleCurrentChange(val) {
      this.queryForm.curPage = val;
      this.queryFriendlink();
    },
    queryFriendlink() {
      console.log(this.queryForm)
      friendlinkApi.queryFriendlink(this.queryForm)
          .then((response) => {
            this.tableData = response.data.data.records;
            console.log(response.data.data.records)
            this.total = response.data.data.total;
          });
    },
    addInfo() {
      this.isSave = true;
      this.dialogTitle = "新增链接";
      this.dialogVisible = true;
      // 重置表单
      this.ruleForm = {};
    },
    handleEdit(index, row) {
      //深拷贝,否则表单中改变值table也会改变
      let tmpRow = Object.assign({},row);
      this.isSave = false;
      this.dialogTitle = "编辑链接";
      this.ruleForm=tmpRow;
      if(tmpRow.icon){
        this.ruleForm.icon = this.serverUrl + tmpRow.icon;
      }
      this.dialogVisible = true;
    },
    handleDelete(index, row) {
      friendlinkApi.delFriendlink(row.id).then((response) => {
        if (response.data.code == 1) {
          this.$notify({
            title: response.data.msg,
            type: "success",
            offset: 40,
            duration: 2000,
          });
          this.tableData.splice(index, 1);
        } else {
          this.$notify.info({
            title: response.data.msg,
            type: "warning",
            offset: 40,
            duration: 3000,
          });
        }
      });
    },
    saveInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if(this.ruleForm.icon!=undefined&&this.ruleForm.icon.indexOf(this.serverUrl) != -1){
            this.ruleForm.icon = this.ruleForm.icon.replaceAll(this.serverUrl,'');
            if(this.ruleForm.icon==null){
              this.ruleForm.icon = "";
            }
          }
          // 调用接口保存
          friendlinkApi.saveFriendlink(this.ruleForm).then((response) => {
            if (response.data.code == 1) {
              this.$notify({
                title: response.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });
              // 关闭窗口
              this.dialogVisible = false;
              // 刷新列表
              this.queryFriendlink();
            } else {
              this.$notify.info({
                title: "保存失败：" + response.data.msg,
                type: "warning",
                offset: 40,
                duration: 2000,
              });

            }
          });
        }
      });
    },
    // 弹窗关闭事件
    handleClose() {
      this.ruleForm = {};
    },
  },
};
</script>

<style scoped>
/deep/ .dcNo_sty .el-input__inner {
  border: none;
  text-align: center;
}

.is-penultimate > .el-tree-node__content {
  color: red;
}
.avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #e9e9eb;
}
.avatar {
  height: 100px;
}

</style>